import { newrelic } from '@mwp/logger';
import { isServer } from '@tanstack/react-query';
import { type ClassValue, clsx } from 'clsx';
import { isNil } from 'lodash';
import { twMerge } from 'tailwind-merge';

import {
  CountryCode,
  CurrencyCode,
  DeviceInfoCookie,
  RawDate,
} from '@/shared/types';

import {
  STORAGE_KEYS,
  countryCollection,
  currencyCollection,
} from './constants';

import { config } from '@/config';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function log(...args: any[]) {
  if (process.env.NODE_ENV !== 'development') newrelic.log(...args);
  // eslint-disable-next-line no-console
  else console.log('[DEV]', ...args);
}

export function debugLog(...args: any[]) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('[DEV]', ...args);
  }
}

export function logError(...args: any[]) {
  if (process.env.NODE_ENV !== 'development') newrelic.error(...args);
  // eslint-disable-next-line no-console
  else console.error('[DEV]', ...args);
}

export function isClient() {
  return typeof window !== 'undefined';
}

export function is401Error(error: any) {
  return error.response?.status === 401;
}

export function is404Error(error: any) {
  return error.response?.status === 404;
}

// =============================================================================
// Case converters
// =============================================================================
export function capitalize(
  string: string,
  {
    lowerRest = false,
    ignoreRest = false,
  }: { lowerRest?: boolean; ignoreRest?: boolean } = {},
) {
  return string
    ?.split(' ')
    .map((word, index) => {
      const firstLetter = (() => {
        if (ignoreRest && index > 0) {
          return word.charAt(0);
        }

        if (lowerRest && index > 0) {
          return word.charAt(0).toLowerCase();
        }

        return word.charAt(0).toUpperCase();
      })();

      const rest =
        ignoreRest && index > 0 ? word.slice(1) : word.slice(1).toLowerCase();

      return `${firstLetter}${rest}`;
    })
    .join(' ');
}

// =============================================================================
// Randomizers
// =============================================================================

export function generateRandomString(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function generateRandomNumber(max: number) {
  return Math.floor(Math.random() * max);
}

// =============================================================================
// DOM / BOM
// =============================================================================

export function getLocalStorageItem(
  name: string,
): Record<string, unknown> | string | null {
  if (typeof localStorage === 'undefined' || isNil(name)) {
    return null;
  }

  const obfuscatedValue = localStorage.getItem(name);
  if (!obfuscatedValue) {
    return null;
  }

  const stringifiedValue = atob(obfuscatedValue);

  try {
    return JSON.parse(stringifiedValue);
  } catch (e) {
    return stringifiedValue;
  }
}

export function setLocalStorageItem(name: string, value: unknown): void {
  if (typeof localStorage === 'undefined' || isNil(value) || isNil(name)) {
    return;
  }

  const stringifiedValue =
    typeof value === 'object' ? JSON.stringify(value) : value?.toString();

  if (isNil(stringifiedValue)) {
    return;
  }

  const obfuscatedValue = btoa(stringifiedValue);

  localStorage.setItem(name, obfuscatedValue);
}

export function removeLocalStorageItem(name: string): void {
  if (typeof localStorage === 'undefined' || isNil(name)) {
    return;
  }
  localStorage.removeItem(name);
}

export function setCookie(
  name: string,
  value: string,
  maxAgeInSeconds: string | number,
): void {
  if (typeof document === 'undefined') {
    return;
  }
  document.cookie = `${name}=${value};  path=/; max-age=${maxAgeInSeconds}`;
}

export function getCookie(name: string): string | undefined {
  if (typeof document === 'undefined') {
    return;
  }
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (key.trim() === name) {
      return value;
    }
  }

  return undefined;
}

export function removeCookie(name: string) {
  if (typeof document === 'undefined') {
    return;
  }
  document.cookie = `${name}=; path=/; max-age=0`;
}

export function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export const storeTokens = (tokens: Array<{ key: string; token: string }>) => {
  for (const { key, token } of tokens) {
    try {
      const { exp } = parseJwt(token) || {};
      setCookie(key, token, exp ?? config.SESSION_EXPIRY);
    } catch (e) {
      logError('Failed to store tokens');
    }
  }
};

export const restoreToken = (key: string): string | undefined => {
  return isServer ? undefined : getCookie(key);
};

export const removeToken = (key: string) => {
  removeCookie(key);
};

export function storeAccessToken(accessToken: string) {
  try {
    const { exp } = parseJwt(accessToken) || {};
    setCookie(
      STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY,
      accessToken,
      exp ?? config.SESSION_EXPIRY,
    );
  } catch (e) {
    logError('Failed to store access token', e);
  }
}

export function restoreAccessToken(): string | undefined {
  return isServer
    ? undefined
    : getCookie(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);
}

export function removeAccessToken() {
  removeCookie(STORAGE_KEYS.CLIENT_ACCESS_TOKEN_STORAGE_KEY);
}

// =============================================================================
// Internationalization
// =============================================================================
export function findCountryCodeByCallingCode(
  callingCode: string,
): CountryCode | undefined {
  for (const [countryCode, countryInfo] of Object.entries(countryCollection)) {
    if (countryInfo.callingCode === callingCode) {
      return countryCode as CountryCode;
    }
  }

  // Return undefined if no matching calling code is found
  return undefined;
}

export function findCountryCodeByCurrencyCode(
  currencyCode: CurrencyCode,
): CountryCode | undefined {
  for (const [countryCode, countryInfo] of Object.entries(countryCollection)) {
    if (countryInfo.currencyCode === currencyCode) {
      return countryCode as CountryCode;
    }
  }

  // Return undefined if no matching currency code is found
  return undefined;
}

export function findCountryCodeByCurrencyName(
  currencyName: string,
): CountryCode | undefined {
  const currencyCode = findCurrencyCodeByName(currencyName);

  if (!currencyCode) {
    return undefined;
  }

  return findCountryCodeByCurrencyCode(currencyCode);
}

export function findCurrencyCodeByName(
  currencyName: string,
): CurrencyCode | undefined {
  for (const [currencyCode, currencyInfo] of Object.entries(
    currencyCollection,
  )) {
    if (currencyInfo.name === currencyName) {
      return currencyCode as CurrencyCode;
    }
  }

  // Return undefined if no matching currency name is found
  return undefined;
}

/**
 * Find the currency code of a country by its country code.
 * @param { CountryCode } countryCode
 * @returns { CurrencyCode | undefined }
 */
export function findCurrencyCodeByCountryCode(
  countryCode: CountryCode,
): CurrencyCode | undefined {
  const countryInfo = countryCollection[countryCode];

  if (!countryInfo) {
    return undefined;
  }

  return countryInfo.currencyCode;
}

/**
 * @param { number } value
 * @param { CurrencyCode } currency
 * @param { Intl.NumberFormatOptions } options
 */
export function formatCurrency(
  value: number,
  currency: CurrencyCode,
  options?: Intl.NumberFormatOptions,
  reversed: boolean = false,
) {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    ...options,
  })
    .format(value)
    .split(/\s/);

  if (reversed) {
    return formattedCurrency.reverse().join(' ');
  }

  return formattedCurrency.join(' ');
}

export function formatDateAsDayMonthName(date: RawDate): string {
  const dateObject = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
  };

  const formattedDate = dateObject.toLocaleDateString('en-US', options);
  if (formattedDate === 'Invalid Date') {
    throw new Error(formattedDate);
  }

  // Split the formatted date into day and month parts
  const [month, day] = formattedDate.split(' ');

  // Return the date as D MMMM
  return `${day} ${month}`;
}

export function convertToRelativeTime(
  today: Date,
  date: RawDate,
): string | void {
  if (!date || new Date(date).toString() === 'Invalid Date') {
    return;
  }

  const dateObj = new Date(date);

  const todayString = formatDateAsYYYYMMDD(today);
  const yesterdayString = formatDateAsYYYYMMDD(
    new Date(new Date(today).setDate(new Date(today).getDate() - 1)),
  );
  const dateString = formatDateAsYYYYMMDD(dateObj);

  const time = dateObj.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  if (todayString === dateString) {
    return `Today at ${time}`;
  } else if (yesterdayString === dateString) {
    return `Yesterday at ${time}`;
  } else {
    return dateObj.toLocaleDateString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }
}

export const convertUtcTimestampToLocalDate = (utcTimestamp: string) => {
  const utcDate = new Date(utcTimestamp); // assuming backend always provides utc date
  const timestamp = utcDate.getTime();
  const timeZoneOffset = utcDate.getTimezoneOffset() * 60 * 1000;
  const date = timestamp - timeZoneOffset;
  return new Date(date);
};

export function formatDateAsDayMonthNameYear(date: RawDate): string {
  const dateObject = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  const formattedDate = dateObject.toLocaleDateString('en-US', options);

  if (formattedDate === 'Invalid Date') {
    throw new Error(formattedDate);
  }

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(' ');

  // Return the date as "day Month Year"
  return `${day.replace(',', '')} ${month} ${year}`;
}

export function getHumanizedDate(date: RawDate): string {
  const dateObject = new Date(date);

  // retrun Day Month if the year is the current year else return Day Month Year
  return dateObject.getFullYear() === new Date().getFullYear()
    ? formatDateAsDayMonthName(date)
    : formatDateAsDayMonthNameYear(date);
}

export function formatDateAsYYYYMMDD(date: RawDate): string {
  const dateObject = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const formattedDate = dateObject.toLocaleDateString('en-US', options);
  if (formattedDate === 'Invalid Date') {
    throw new Error(formattedDate);
  }

  const [month, day, year] = formattedDate.split('/');
  return `${year}-${month}-${day}`;
}

export function formatNumber(
  value: number,
  options?: Intl.NumberFormatOptions,
) {
  return new Intl.NumberFormat('en-US', options).format(value);
}

export const getRedirectUrl = (key: string) => {
  const redirectPath = getCookie(key);
  if (redirectPath) {
    return decodeURIComponent(redirectPath);
  }
};

export const setRedirectUrl = (
  key: string,
  {
    url,
    expiry = 1000 * 60 * 60 * 24,
  }: { url?: string | null; expiry?: number } = {},
) => {
  if (url) {
    setCookie(key, encodeURIComponent(url), expiry);
  } else {
    removeCookie(key);
  }
};

export const getExistingDeviceInfo = () => {
  const deviceInfo = getLocalStorageItem('deviceInfo');
  if (deviceInfo) {
    return deviceInfo as unknown as DeviceInfoCookie;
  }
};

export const setDeviceInfoInLocalstorage = (deviceInfo: DeviceInfoCookie) => {
  setLocalStorageItem('deviceInfo', deviceInfo);
};
